<template>
  <v-row class="text-center">
    <v-col class="col-sm-4 d-none d-sm-flex"></v-col>
    <v-spacer></v-spacer>
    <v-col align="center" class="col-12 col-sm-3">
      <div style="background-color: red; color: white; padding: 16px; margin-top: 16px; border-radius: 8px;">
        Due to recent upgrades to the site, your user account may have been updated. Please <router-link :to="{ path: 'auth-management', query: { mode: 'resetPassword' } }">reset your password</router-link> if you have trouble logging in.
      </div>
      <v-card class="mt-5 pa-5">
        <v-form
          @submit.prevent
          ref="form"
          v-model="valid"
        >
          <h2>Log In</h2>
          <v-text-field
            v-if="!userData || !userData.isAuth"
            v-model="email"
            :rules="requiredRules"
            label="Email"
            required
            @keydown.enter="login"
          ></v-text-field>
          <v-text-field
            v-if="!userData || !userData.isAuth"
            v-model="password"
            :rules="requiredRules"
            :type="'password'"
            label="Password"
            required
            @keydown.enter="login"
          ></v-text-field>
          <v-btn
            v-if="!userData || !userData.isAuth"
            :loading="loading"
            @click="login"
            color="primaryButton"
            class="mt-3 white--text"
          >
            Log In
          </v-btn>
          <v-btn
            v-else
            @click="logout"
            color="primaryButton"
            class="mt-3 white--text"
          >
            Log Out
          </v-btn>
          <div class="mt-3"><router-link :to="{ path: 'auth-management', query: { mode: 'resetPassword' } }">Forgot Password?</router-link></div>
          <div class="error--text mt-3">{{ error }}</div>
        </v-form>
      </v-card>
    </v-col>
    <v-spacer></v-spacer>
    <v-col class="col-sm-4 d-none d-sm-flex"></v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Login',

    props: ['isRecentAuthError'],

    data () {
      return {
        email: '',
        error: '',
        loading: false,
        password: '',
        requiredRules: [
          v => !!v || 'Field is required.'
        ],
        valid: false
      }
    },

    computed: {
      ...mapGetters([
        'userData'
      ])
    },

    mounted () {
      if (this.userData && this.userData.isAuth) this.$refs.emailField.focus()
      if (this.$route.params.isRecentAuthError) this.error = 'You must have logged in recently in order to perform that action. Please log in again to continue.'
    },

    methods: {
      async login () {
        this.error = ''
        if (this.valid && !this.loading) {
          this.loading = true
          if (this.email.indexOf("@") == -1) this.email += "@qabbb.com"
          const { email, password } = this
          await this.$store.dispatch('login', { email, password })
          .then((resp) => {
            this.loading = false
            if (resp == true) {
              this.$gtag.event('login', { method: 'Google' })
              this.$vuetify.theme.dark = this.userData.isDarkMode
            } else { // if login was not successful, show error
              this.error = 'Invalid email or password.' //resp.message
            }
          })
          .catch((err) => {
            this.loading = false
            err = 'Invalid email or password.'
            this.error = err
          })
        }
      },

      logout () {
        this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login')
        })
        .catch((err) => {
          this.error = err
        })
      }
    }
  }
</script>
